import React from "react";

import Figure from "react-bootstrap/Figure";

import nsElementPicture from "./NSSCourse/elements.png"; 

function NSSCourse() {
  return (
    <section>
      <h2>MI-NSS: Normalized Software Systems <small>(FIT CTU course)</small></h2>

      <p>
        During this course, students will learn the foundations of Normalized Systems theory, which studies the evolvability of modular structures based on concepts from engineering such as stability from systems theory and entropy from thermodynamics. Initially, the theory was developed at the level of software architectures, where the concept of stability was  translated into the definition of so-called combinatorial effects. These effects occur when the impact of a change to  the software architecture is dependent on the change itself, as well as on the size of the system. The latter is highly undesirable, as it will cause even a simple change to incur an ever-increasing impact as the size of the system grows over time. As such, combinatorial effects can be considered as a main cause of <a href="http://users.ece.utexas.edu/~perry/work/papers/feast1.pdf" target="_blank">Lehman's Law of Increasing Complexity</a>. Recently, Normalized Systems theory was also applied to the modular structures in business processes and enterprise architectures, with the goal of constructing a foundational theory for Enterprise Engineering.
      </p>

      <Figure className="figure">
        <Figure.Image
          alt="NS Elements and Separation of Concerns"
          src={nsElementPicture}
        />
        <Figure.Caption>
          NS Elements and Separation of Concerns (NS Academy: Toward Innovation by Evolvable Modular Design)
        </Figure.Caption>
      </Figure>

      <p>
        The subject is organised during the semester as two or three days of lectures given by authors of the Normalized Systems theory, prof. Jan Verelst and prof. Herwig Mannaert. The course covers the theoretical and practical aspects of using the theory in domain of Software Engineering. It focuses mainly on correct understanding of evolvability, core NS principles, and its use in practice with NS elements and expanders for creation of evolvable enterprise information systems. During the semester, practical session with a demonstration of tools and evolvable software generation shows the true power of the theory turned in practice. The course is completed by written examination where students prove their understanding of the core concepts.
      </p>
    </section>
  );
}

export default NSSCourse;
