import React from "react";

import ListGroup from "react-bootstrap/ListGroup";

function Links() {
  // TODO: links as JS list
  return (
    <section>
      <h2>Links</h2>

      <h3>Normalized Systems</h3>
      <ListGroup className="links-list">
        <ListGroup.Item as="a" href="https://normalizedsystems.org" target="_blank">NSX: Normalized Systems</ListGroup.Item>
        <ListGroup.Item as="a" href="https://foundation.stars-end.net" target="_blank">Normalized Systems online documentation</ListGroup.Item>
        <ListGroup.Item as="a" href="https://www.koppa.be/?page_id=672" target="_blank">Normalized Systems Theory (book)</ListGroup.Item>
      </ListGroup>

      <h3>Double-Degree PhD</h3>
      <ListGroup className="links-list">
        <ListGroup.Item as="a" href="https://www.uantwerpen.be/en/research/phd/antwerp-doctoral-school/joint-phd/" target="_blank">University of Antwerp: Joint PhD</ListGroup.Item>
      </ListGroup>

      <h3>NS Papers and Slides</h3>
      <ListGroup className="links-list">
        <ListGroup.Item as="a" href="https://link.springer.com/article/10.1007/s12599-017-0510-4" target="_blank">Enabling Normalized Systems in Practice–Exploring a Modeling Approach (2018)</ListGroup.Item>
        <ListGroup.Item as="a" href="https://ieeexplore.ieee.org/abstract/document/6759187/" target="_blank">Building Evolvable Software Using Normalized Systems Theory: A Case Study (2014)</ListGroup.Item>
        <ListGroup.Item as="a" href="https://www.researchgate.net/profile/Herwig_Mannaert/publication/268185496_Towards_Applying_Normalized_Systems_Concepts_to_Modularity_and_the_Systems_Engineering_Process/links/54ad28280cf24aca1c6ce23d/Towards-Applying-Normalized-Systems-Concepts-to-Modularity-and-the-Systems-Engineering-Process.pdf" target="_blank">Towards Applying Normalized Systems Concepts to Modularity and the Systems Engineering Process (2012)</ListGroup.Item>
      </ListGroup>
    </section>
  );
}

export default Links;
