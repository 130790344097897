import React from "react";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";

import fitctuLogo from './Footer/fit_ctu.svg';
import uantwerpenLogo from './Footer/uantwerpen.svg';
import nsxLogo from './Footer/nsx.png';
import ccmiLogo from './Footer/ccmi_logo.svg';

function Footer() {
  return (
    <footer className="page-footer">
      <Container>
        <Row>
          <Col md="8" className="footer-logos">
            <div id="nsx-logo" className="footer-logo">
              <a href="https://normalizedsystems.org" target="_blank">
                <Image src={nsxLogo} />
              </a>
            </div>
            <div id="fit-logo" className="footer-logo">
              <a href="https://fit.cvut.cz/en" target="_blank">
                <Image src={fitctuLogo} />
              </a>
            </div>
            <div id="ua-logo" className="footer-logo">
              <a href="https://www.uantwerpen.be/en/" target="_blank">
                <Image src={uantwerpenLogo} />
              </a>
            </div>
            <div id="ccmi-logo" className="footer-logo">
              <a href="https://ccmi.fit.cvut.cz/en/" target="_blank">
                <Image src={ccmiLogo} />
              </a>
            </div>
          </Col>
          <Col md="4">
              <p>For further information, contact:<br/><a href="mailto:marek.suchanek@fit.cvut.cz">marek.suchanek@fit.cvut.cz</a>
              </p>
          </Col>
        </Row>
      </Container>
      <div className="footer-copyright">
        <span>FIT CTU in Prague &copy; 2020</span>
      </div>
    </footer>
  );
};

export default Footer;
