import React from "react";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { NavLink } from "react-router-dom";

class Navigation extends React.Component {
  render() {
    return( 
      <Navbar collapseOnSelect expand="lg" variant="dark" bg="primary" sticky="top">
        <Container>
          <Navbar.Brand href="/">NS @ FIT CTU in Prague</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto"></Nav>
            <Nav>
              <Nav.Link exact to="/" as={NavLink}>Intro</Nav.Link>
              <Nav.Link to="/mi-nss" as={NavLink}>MI-NSS</Nav.Link>
              <Nav.Link to="/theses" as={NavLink}>Theses</Nav.Link>
              <Nav.Link to="/double-phd" as={NavLink}>Double PhD</Nav.Link>
              <Nav.Link to="/media" as={NavLink}>Media</Nav.Link>
              <Nav.Link to="/links" as={NavLink}>Links</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default Navigation;
