import React from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";

import profesorPicture from './Intro/profesor.svg';
import researchPicture from './Intro/researching.svg';
import swengPicture from './Intro/software_engineer.svg';


function NSSRow() {
    return (
        <Row className="intro-row" id="nss">
            <Col md="5" className="intro-img-col left">
                <Image src={profesorPicture} fluid />
            </Col>
            <Col md="7" className="intro-text-col">
                <h2>Explore evolvability of systems</h2>

                <p>You have a <em>unique opportunity</em> to explore the world of <abbr title="Software evolvability is a multifaceted quality attribute that describes a software system's ability to easily accommodate future changes.">evolvability</abbr> (not only in software). Every summer semester, students can enroll in the MI-NSS course where they learn about Normalized Systems (NS) in both theory and practical use. This eye-opening or even life-changing experience is possible to enjoy only at two places in the world &ndash; at our faculty and at the University of Antwerp. The lecturers are professors <a href="https://www.uantwerpen.be/en/staff/jan-verelst/" target="_blank">Jan Verelst</a> and <a href="https://www.uantwerpen.be/en/staff/herwig-mannaert/" target="_blank">Herwig Mannaert</a> from Antwerp, the authors of <abbr title="Normalized Systems Theory">NST</abbr> and founders of NSX spin-off company.</p>

                <Button variant="secondary" to="/mi-nss" as={Link} className="card-button">Read more about the <em>MI-NSS course</em></Button>
            </Col>
        </Row>
    );
}

function ThesesRow() {
    return (
        <Row className="intro-row" id="theses">
            <Col md="7" className="intro-text-col">
                <h2>Normalized Master's Thesis</h2>

                <p>Your journey in the world of <abbr title="Software evolvability is a multifaceted quality attribute that describes a software system's ability to easily accommodate future changes.">evolvability</abbr> and <abbr title="Modularity refers to the extent to which a software or application may be divided into smaller modules.">modularity</abbr> does not have to end with the MI-NSS course. You can apply the knowledge in practice in your own projects, or your Master's thesis. Together with professors from Antwerp, we offer various topics and you can come with your own as well. The topics are both theoretical and practical with possible future engagement in PhD or attending <em>NS Summer School</em>. Having such a successful international project in your <abbr title="Curriculum Vitæ">CV</abbr> is definitely a huge benefit. IT can also open doors to NSX for your further collaboration in world-changing software development.</p>

                <Button variant="secondary" to="/theses" as={Link} className="card-button">See the <em>offered theses topics</em></Button>
            </Col>
            <Col md="5" className="intro-img-col right">
                <Image src={researchPicture} fluid />
            </Col>
        </Row>
    );
}

function DoublePhDRow() {
    return (
        <Row className="intro-row" id="double-phd">
            <Col md="5" className="intro-img-col left">
                <Image src={swengPicture} fluid />
            </Col>
            <Col md="7" className="intro-text-col">
                <h2>Do the science &amp; get two PhDees</h2>

                <p>Edward Teller once said: <q>The science of today is the technology of tomorrow</q>. There is yet another unique opportunity for you &ndash; <em>become a double scientist</em>. Our PhD students are able to study both <abbr title="Faculty of Information Technology, Czech Technical University in Prague">FIT CTU</abbr> and <abbr title="Faculty of Applied Economics, University of Antwerp">FAE UA</abbr> at the same time with the same dissertation topic. After a successful defense, the student is awarded by two PhD diplomas, one from each university. That will not only make your parents proud of you but also skyrocket your career.</p>

                <Button variant="secondary" to="/double-phd" as={Link} className="card-button">Start researching the <em>double PhD conditions</em></Button>
            </Col>
        </Row>
    );
}

function IntroNav() {
    return (
        <ul id="intro-nav">
            <li>
                <a href="#nss">MI-NSS Course</a>
            </li>
            <li>
                <a href="#theses">Theses</a>
            </li>
            <li>
                <a href="#double-phd">Double-Degree PhD</a>
            </li>
        </ul>
    );
}

function Intro() {
    return (
        <div id="intro">
            <h1>Normalized Systems @ FIT CTU in Prague</h1>
            <IntroNav />
            <hr />
            <NSSRow />
            <hr/>
            <ThesesRow />
            <hr/>
            <DoublePhDRow />
        </div>
    );
}

export default Intro;