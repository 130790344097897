import React from "react";

import { useLocation } from "react-router-dom";

function NoMatch() {
  let location = useLocation();

  return (
    <div>
      <h3>
        There is no such page <code>{location.pathname}</code> ...
      </h3>
    </div>
  );
}

export default NoMatch;
