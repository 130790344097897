import React, { useState } from "react";

import Container from "react-bootstrap/Container";
import { 
  BrowserRouter as Router, 
  Route,
  Switch
} from "react-router-dom";

import Footer from "./components/common/Footer";
import NoMatch from "./components/common/NoMatch";
import Navigation from "./components/common/Navigation";

import Intro from "./components/content/Intro";
import NSSCourse from "./components/content/NSSCourse";
import Theses from "./components/content/Theses";
import DoublePhD from "./components/content/DoublePhD";
import Media from "./components/content/Media";
import Links from "./components/content/Links";

const App = () => (
  <Router>
    <main className="content">
      <Navigation />

      <Container className="p-3">
        <Switch>
          <Route exact path="/">
            <Intro />
          </Route>
          <Route path="/mi-nss">
            <NSSCourse />
          </Route>
          <Route path="/theses">
            <Theses />
          </Route>
          <Route path="/double-phd">
            <DoublePhD />
          </Route>
          <Route path="/media">
            <Media />
          </Route>
          <Route path="/links">
            <Links />
          </Route>
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </Container>
    </main>
    <Footer />
  </Router>
);

export default App;
