import React from "react";

import Accordion from "react-bootstrap/Accordion";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ProgressBar from "react-bootstrap/ProgressBar"

import CONFIG from "../../config";

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

class ThesisTopics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      result: []
    };
  }

  componentDidMount() {
    sleep(666).then(
      () => {
        fetch(CONFIG.thesesTopicsEndpoint)
          .then(res => res.json())
          .then(
            (result) => {
              this.setState({
                isLoaded: true,
                result
              });
            },
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });
            }
          );
      }
    );
  }

  render() {
    const { error, isLoaded, result } = this.state;
    if (isLoaded) {
      return error ? this.renderError() : this.renderResult(result);
    } else {
      return this.renderLoading();
    }
  }

  renderLoading() {
    return <ProgressBar animated variant="secondary" now={100} />;
  }

  renderError() {
    return <p className="error-message">Currently, we could not get topics for the faculty system... Try again later.</p>;
  }

  renderResult(result) {
    const resultMap = new Map(Object.entries(result));
    const isOkey = resultMap.get("returnCode") === "0" && resultMap.has("generalTopics");
    if (isOkey) {
      return this.renderTopics(resultMap.get("generalTopics").item);
    } else if (resultMap.has("message")) {
      return <p className="error-message">No topics are offered currently... Try again later.</p>;
    } else {
      return this.renderError();
    }
  }

  renderTopics(topics) {
    const items = [];
    topics = Array.isArray(topics) ? topics : [topics];
    topics.forEach((topic, id) => {
      if (topic.id && topic.titleEn && topic.descriptionEn) {
        items.push(
          <Card key={topic.id}>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey={topic.id}>
                {topic.titleEn}
              </Accordion.Toggle>
              <span className="topic-badges">
                <TopicTypeBadge type={topic.type} />
                <TopicReservationsBadge reserved={topic.reservationsCurrent} total={topic.reservationsMax} />
                <TopicContactBadge username={topic.authorLogin} />
              </span>
            </Card.Header>
            <Accordion.Collapse eventKey={topic.id}>
              <Card.Body>
                <div dangerouslySetInnerHTML={{ __html: topic.descriptionEn }}></div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        );
      }
    });

    return (
      <Accordion className="thesis-topics">
        {items}
      </Accordion>
    );
  }
}

function TopicTypeBadge(props) {
  if (props.type === "MASTER") {
    return <Badge variant="secondary">master</Badge>;
  } else {
    return <Badge variant="primary">bachelor</Badge>;
  }
}

function TopicReservationsBadge(props) {
  const reserved = Number.parseInt(props.reserved);
  const total = Number.parseInt(props.total);
  if (reserved >= total) {
    return <Badge variant="danger">reserved</Badge>;
  } else if (reserved == 0) {
    return <Badge variant="success">free</Badge>;
  } else {
    return <Badge variant="warning">{reserved} / {total}</Badge>;
  }
}

function TopicContactBadge(props) {
  return <Badge variant="info">{props.username}</Badge>;
}

export default ThesisTopics;
