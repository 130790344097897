import React from "react";

import ThesisTopics from "./ThesisTopics";

function Theses() { 
  return (
    <section>
      <h2>Theses Topics</h2>

      <p>
        Here you can find a list of offered theses topics that are available for reservation in our Faculty system for theses. If you have any questions or ideas for different related topic, please feel free to contact us.
      </p>
      
      <p>
        In case of interest contact the person (<i>username</i>@fit.cvut.cz) or reserve the topic directly in the <a href="https://is.fit.cvut.cz">Faculty system</a>.
      </p>

      <ThesisTopics />


    </section>
  );
}

export default Theses;
