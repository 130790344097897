import React from "react";

import Card from "react-bootstrap/Card";
import CardColumns from "react-bootstrap/CardColumns";

const entries = [
  {
    title: "NS Research Summer 2019",
    subtitle: "CCMi News (English)",
    text: "As part of our developing cooperation with the University of Antwerp (Belgium) and NSX spin-off that applies the Normalized Systems theory in practice, our PhD students Marek Suchánek, Jan Slifka, and Vojtěch Knaisl again attended the summer school. During the August they worked with experts (including our graduate Peter Uhnák, who is employed at NSX) and professors directly at NSX near Antwerp on their research topics.",
    date: "September 1, 2019",
    links: [
      {
        url: "https://ccmi.fit.cvut.cz/ns-research-summer-2019/",
        label: "CCMi website"
      }
    ]
  },
  {
    title: "Two doctoral degrees in one PhD study at the FIT CTU in Prague",
    subtitle: "CTU News (Czech only)",
    text: "The Faculty of Information Technology of CTU in Prague (FIT CTU) has established intensive cooperation in science and research with the Belgian University of Antwerp. Thanks to the unique double doctoral study program, it is possible to complete both universities at the same time, and in a significantly shorter time than if the study were conducted separately. The result is diplomas from a domestic and friendly foreign university.",
    date: "August 6, 2019",
    links: [
      {
        url: "https://fit.cvut.cz/pr/tiskove-zpravy_2019/spoluprace-antverpy",
        label: "Press Release (FIT CTU)"
      },
      {
        url: "https://aktualne.cvut.cz/tiskove-zpravy/20190806-dva-doktorske-diplomy-v-ramci-jednoho-studia-exkluzivne-nabizi-fakulta",
        label: "CTU News"
      },
      {
        url: "https://www.novinky.cz/veda-skoly/clanek/cvut-nabizi-dva-doktorske-tituly-v-ramci-jednoho-studia-druhy-na-belgicke-skole-40292906",
        label: "Novinky.cz"
      },
    ]
  }
]

function Media() {
  let cards = [];

  entries.forEach((entry, index) => {
    let links = [];

    entry.links.forEach((link, index) => {
      links.push(
        <li key={index}><Card.Link href={link.url}>{link.label}</Card.Link></li>
      );
    });

    cards.push(
      <Card key={index}>
        <Card.Body>
          <Card.Title>{entry.title}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{entry.subtitle}</Card.Subtitle>
          <Card.Text>{entry.text}</Card.Text>
          <ul>{links}</ul>
        </Card.Body>
        <Card.Footer className="text-muted">{entry.date}</Card.Footer>
      </Card>
    );
  });

  return (
    <section>
      <h2>Media</h2>

      <p>
        Here you can find information related to the collaboration between FIT CTU in Prague and University of Antwerp published in media (press, web pages, etc.):
      </p>

      <CardColumns>
        {cards}
      </CardColumns>
    </section>
  );
}

export default Media;
