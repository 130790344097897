import React from "react";

function DoublePhD() {
  return (
    <section>
      <h2>Double-Degree PhD</h2>
      
      <p>
        Since 2019, there is a possibility for our PhD students to enroll in a doctoral study program at the Faculty of Applied Economics, University of Antwerp as joint PhD. This basically means that you can study on both universities the same topic and after defense of the thesis you will be awarded by two diplomas, one from each institution. Your research topic must be naturally connected to topics of both universities and a collaboration with researchers from both institutions is required. The effort into the agreement was put mainly for topics related to Normalized Systems Theory but it is not strictly limited to that. 
      </p>

      <p>In case of any further questions, please contact the local NS/Double-Degree coordinator <a href="mailto:marek.suchanek@fit.cvut.cz">Marek Suchánek</a>.</p>

      <h3>Requirements</h3>

      <p>
        There are several requirements that you should fulfill in order to apply for the Double Degree PhD, the main being:
      </p>

      <ul>
        <li>PhD student at the Faculty of Information Technology, Czech Technical University in Prague</li>
        <li>Dissertation topic aligned with the collaboration with the University of Antwerp</li>
        <li>One or more years until defense</li>
        <li>Enthusiasm for the science</li>
        <li>Advanced level of English</li>
      </ul>

      <h3>The Process</h3>

      <ol>
        <li>Discuss the PhD topic with the supervisor and the local NS coordinator. They will also provide you with assistance in the following steps.</li>
        <li>Initiate the work on joint PhD agreement (contract between the universities and the student).</li>
        <li>Submit the joint PhD application.</li>
        <li>Enroll at the University of Antwerp (UA).
          <ol>
            <li>Send the required documents (copies of ID and diploma, photo, certificate of study from CTU, etc.).</li>
            <li>Pay the tuition fee (indicative amount: €500, may be reimbursed in the form of a stipendium in case of good results).</li>
            <li>Get certificate of study from UA, student ID card, and access to study systems.</li>
          </ol>
        </li>
        <li>Finalize the joint PhD agreement.</li>
        <li><strong>Do the science!</strong>
          <ol>
            <li>Work on the topic according to the plan approved by the supervisors</li>
            <li>Collaborate with involved people from both universities (you are required to conduct your research efforts at each institution for at least 6 months).</li>
            <li>Report the progress regularly as you agreed with supervisors.</li>
          </ol>
        </li>
        <li>Defend your dissertation and get two diplomas.</li>
      </ol>

    </section>
  );
}

export default DoublePhD;
